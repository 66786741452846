<template>
<main class="order">
  <section class="main sect-page -flex-m sect-min">
<div class="">
       <div class=" about">
<p>
 
 Genetil is a producer and visual artist. <br>
  He discovered electronic music by influences such as Martin Garrix, San Holo and Flume. <br> Interested by various styles of music, he focus particularly on Emotional Dance Music, Lo-Fi and Future Bass. <br> His creativity and his refreshing personality make Genetil without doubt a futur artist of the electronic scene.
  <!-- « Fail fast, fail often ». <br>
I learn more from my mistakes and tame failure. -->
</p>
  
<div class=" Gap -flex-m">
  <a class="title-link black-b four-block all-centred " target="_blank" href="https://linktr.ee/genetilmusic"> LISTEN </a>
</div>

</div> 

</div>


</section>
  <Footer/>
</main>
</template>

<script>
import Footer from "@/components/footer.vue"
export default {

components:{
  Footer,
},
mounted(){ window.scrollTo(0,0)},
}
</script>

<style lang="css" scoped>
 a{
  /* width: 100%; */
}
.toblock{
  display: block;
}
.black-b{
  color: #2B2B2B;
}
.blue-linkdin{
  color: #0966c2; 
}
.green-c{
  color: #34c22f;
}
.orange{
  color: #ff7d39;
}
.contact-text{
  grid-area: main-end1;
  color:#44494D; 
  
  
}

.Gap{
margin-top: 1rem;
}
.title-link{
  font-size: 0.9rem;
  font-family: NeueMontreal-medium;
    height: 150px;
}
.first-block{
  grid-area: main-row1;
  width: 90%;
   border: solid 20px #44494D; 
 
  
}
.second-block{
  grid-area: main-end2;
  width: 50%;
  border: solid 20px #0966c2; 
}
.third-block{
  grid-area: main-row3/main-row3/main-row3/main-end3;
  width: 65%; 
   display: flex;
  justify-self: end;
   border: solid 8px #2B2B2B;

}
.four-block{
  
  margin-left: 5px;
  margin-right: 5px;
height: 7vh;
width: 40%;
  grid-area: main-row2/main-row4/main-row4/main-end4;
  
   border: solid 3px #2B2B2B;

}

.five-block{
  
  grid-area: main-end3;
  width: 55%; 
  display: flex;
  justify-self: end;
   border: solid 20px #34c22f;

}
.last-block{
  
  grid-area: main-row2;
  width: 55%; 
  display: flex;
  justify-self: center;
   border: solid 20px #2B2B2B;

}
.about p{
 text-align:center ;
  font-family: "NeueMontreal-medium";
  font-size: 1rem;
  line-height: 1.5;
  /* width: 90%; */
  flex-wrap: wrap;
}
.all-centred{
  display: flex;
  align-items: center;
  justify-content: center;
}

.order{
  z-index: -1000;
}
.grid-gallery-contact{
        
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        position: relative;
        grid-template-columns: 0.5fr repeat(2,1fr) 0.5fr;
        grid-template-rows: 0.2fr repeat(2, 1fr)  repeat(2, 1fr) 0.5fr;
        grid-gap: 3.5rem;
        
        grid-template-areas: 
        "top top top  top" 
        "aside1 main-row1  main-end1 col"
         "aside2 main-row2  main-end2 col" 
         
         "aside3 main-row3    main-end3 col"
         "aside4 main-row4   main-end4 col"
          "foot foot foot  foot"
        ;
        
        }

/* repeat(2,1fr) repeat(2,0.5fr)repeat(2,1fr) A faire !! */

@media only screen and (max-width: 1600px){
 /* Grid pour les ecrans < 1600px */ 
         .grid-gallery-contact{
           grid-gap: 2rem;
        grid-template-columns: 0.3fr repeat(2,1fr) 0.3fr;
        
        grid-template-areas: 
        "top top top  top" 
        "aside1 main-row1  main-end1 col"
         "aside2 main-row2  main-end2 col" 
         
         "aside3 main-row3    main-end3 col"
         "aside4 main-row4   main-end4 col"
          "foot foot foot  foot"
        ;
        } 


        .about p{
  font-family: "NeueMontreal-medium";
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;

}

        }


@media only screen and (max-width: 1005px){
 /* Grid pour les ecrans < 1005px */ 
          .grid-gallery-contact{
           grid-gap: 2rem;
        grid-template-columns: 0.2fr repeat(2,1fr) 0.2fr;
        
        grid-template-areas: 
        "top top top  top" 
        "aside1 main-row1  main-end1 col"
         "aside2 main-row2  main-end2 col" 
         
         "aside3 main-row3    main-end3 col"
         "aside4 main-row4   main-end4 col"
          "foot foot foot  foot"
        ;
        } 


.title-link {

}
.about p{
  font-family: "NeueMontreal-medium";
  font-size: 1rem;
  line-height: 1.2;

}


        }




@media only screen and (max-width: 735px){
   /* Grid pour les ecrans < 735px */
         .grid-gallery-contact{
        grid-template-columns:  repeat(2,1fr) ;
        
        grid-template-areas: 
        " top  top" 
        " main-row1  main-end1 "
         " main-row2  main-end2" 
         
         " main-row3    main-end3"
         " main-row4   main-end4 "
          " foot foot "
        ;

        } 
        .about p{
      line-height: normal;
      font-size: 0.9rem;
    }

    .four-block{
border: none;}

        }

@media only screen and (max-width: 630px) {
          body{
            padding: 0;
          }
  /* Grid pour les ecrans < 630px */
        .grid-gallery-contact{
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr  repeat(8, .5fr) 0.4fr;
        grid-row-gap: 1.5rem;
        grid-template-areas: 
        "top"
        " main-row1"
        " main-end1"
        "main-row2 "
        "main-end2 "
        "main-row3 "
        "main-end3 "
        "main-row4 "
        "main-end4 "
        "foot"
        ;
        }
        .about p{       text-align: justify;


}
        .four-block{
  /* color: #2c78dc; */
  
  height: 50%;}
}
</style>